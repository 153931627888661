import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import { NationalInstituteData } from "@/interfaces/modules/institute/national_global_comparison_choice";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { Programs } from "@/interfaces/data_objects/programs";

export interface NationalJobComparison {
  loading: boolean;
  active_skill_group: string;
  extracted_skills: ExtractedSkillsWithGroup.Skills[];
  active_tab: ActionsTabs;
  selected_sector: number;
  selected_job: number;
  national_institute_data: NationalInstitute;
}

export enum ActionsTabs {
  DEGREE_SKILLS,
  MATCHED_SKILLS,
  SKILLS_GAP
}

interface NationalInstitute extends NationalInstituteData {
  institutes: Institutes[];
  selected_institute_programs: Programs[];
}
