














































































































































































































































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  DEGREE_GAP_LOADING,
  DEGREE_JOB_COMPARISON,
  FETCH_INSTITUTE_PROGRAM,
  INSTITUTE_STATE_LOADING,
  LOCAL_INSTITUTES,
  NATIONAL_DEGREE_LOADING,
  NATIONAL_DEGREE_SELECTED_SECTOR_JOB_MATCH_GAP,
  NATIONAL_DEGREE_SELECTED_SECTOR_JOBS,
  NATIONAL_DEGREE_SKILLS,
  NATIONAL_DEGREE_TOPJOB_SECTORS,
  SELECTED_JOB_SCORE,
  SELECTED_NATIONAL_INSTITUTE,
  UPSKILL_COURSE_PAYLOAD
} from "@/store/modules/institute/constants";
import DataLoading from "@/components/shared/DataLoading.vue";
import SkillsGroupV2 from "@/components/shared/SkillsGroupV2.vue";
import SkillsChipsV2 from "@/components/shared/SkillsChipsV2.vue";
import {
  ActionsTabs,
  NationalJobComparison
} from "@/interfaces/modules/institute/national_job_comparison";
import { filter_skills_based_on_group } from "@/utils/skills_group";
import { Institutes } from "@/interfaces/data_objects/institutes";
import { Programs } from "@/interfaces/data_objects/programs";
import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import {
  UpskillCoursesPayload,
  UpskillCoursesPayloadTypes
} from "@/store/modules/institute/interfaces";
import { ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import GoBackHeader from "@/components/shared/GoBackHeader.vue";

export default Vue.extend({
  name: "NationalJobComparison",
  components: { GoBackHeader, SkillsChipsV2, SkillsGroupV2, DataLoading },
  data(): NationalJobComparison {
    return {
      loading: false,
      active_skill_group: "Leadership and Management",
      extracted_skills: [],
      active_tab: ActionsTabs.DEGREE_SKILLS,
      selected_sector: 0,
      selected_job: 0,
      national_institute_data: {
        national_institute: null,
        selected_national_degree_file: null,
        selected_national_degree_content: "",
        national_institute_program: null,
        institutes: [],
        selected_institute_programs: []
      }
    };
  },
  computed: {
    ActionsTabs() {
      return ActionsTabs;
    },
    ...mapGetters("institute", {
      get_institute_loading: INSTITUTE_STATE_LOADING,
      national_degree_skills: NATIONAL_DEGREE_SKILLS,
      national_degree_loading: NATIONAL_DEGREE_LOADING,
      degree_gap_loading: DEGREE_GAP_LOADING,
      national_degree_topjob_sectors: NATIONAL_DEGREE_TOPJOB_SECTORS,
      national_degree_selected_sector_jobs:
        NATIONAL_DEGREE_SELECTED_SECTOR_JOBS,
      get_selected_sector_job_match_gap:
        NATIONAL_DEGREE_SELECTED_SECTOR_JOB_MATCH_GAP,
      get_national_institute: SELECTED_NATIONAL_INSTITUTE,
      get_local_universities: LOCAL_INSTITUTES,
      get_selected_job_score: SELECTED_JOB_SCORE
    })
  },
  // route validator to check user selected file for comparison or not
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const national_institute =
        vm.$store.getters[`institute/${SELECTED_NATIONAL_INSTITUTE}`];
      if (!national_institute.national_institute) {
        next("/institute/comparison/national-job");
      } else next();
    });
  },
  watch: {
    async national_degree_loading(new_value: boolean) {
      if (!new_value) {
        // National institute setting
        this.national_institute_data.national_institute =
          this.get_national_institute.national_institute;
        this.national_institute_data.national_institute_program =
          this.get_national_institute.national_institute_program;
        // Fetching national institute program
        this.national_institute_data.selected_institute_programs =
          await this.fetch_institute_program(
            this.get_national_institute.national_institute.id
          );
        this.configure_skills();
      }
    }
  },
  async mounted() {
    this.national_institute_data.institutes = this.get_local_universities;
    if (
      !this.national_degree_loading &&
      this.get_national_institute.national_institute
    ) {
      // National institute setting
      this.national_institute_data.national_institute =
        this.get_national_institute.national_institute;
      this.national_institute_data.national_institute_program =
        this.get_national_institute.national_institute_program;
      // Fetching national institute program
      this.national_institute_data.selected_institute_programs =
        await this.fetch_institute_program(
          this.get_national_institute.national_institute.id
        );
      this.configure_skills();
    }
  },
  methods: {
    ...mapMutations({
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("institute", {
      fetch_institute_program: FETCH_INSTITUTE_PROGRAM,
      fetch_degree_job_comparison: DEGREE_JOB_COMPARISON
    }),
    ...mapMutations("institute", {
      set_degree_gap_loading: DEGREE_GAP_LOADING,
      set_national_degree_loading: NATIONAL_DEGREE_LOADING,
      set_national_institute: SELECTED_NATIONAL_INSTITUTE,
      set_upskill_courses_payload: UPSKILL_COURSE_PAYLOAD
    }),
    update_skill_group(skill_group: string) {
      this.active_skill_group = skill_group;
      this.configure_skills();
    },
    configure_skills() {
      // If degree skills tab is active
      if (this.active_tab === ActionsTabs.DEGREE_SKILLS) {
        this.extracted_skills = filter_skills_based_on_group(
          this.national_degree_skills.skills,
          this.active_skill_group
        );
      }
      // If matched skills tab is active
      else if (this.active_tab === ActionsTabs.MATCHED_SKILLS) {
        this.extracted_skills = this.get_selected_sector_job_match_gap(
          this.selected_sector,
          this.selected_job,
          true
        );
      }
      // If matched skills tab is active
      else if (this.active_tab === ActionsTabs.SKILLS_GAP) {
        this.extracted_skills = this.get_selected_sector_job_match_gap(
          this.selected_sector,
          this.selected_job,
          false
        );
      }
    },
    /**
     * Function to process selected national institute
     * Fetching institute programs
     * @param value
     */
    async process_selected_institute(value: Institutes | null) {
      // If institute selected => fetch programs
      if (value) {
        this.national_institute_data.selected_institute_programs =
          await this.fetch_institute_program(value.id);
      }
      // Else reset program related data
      else {
        this.national_institute_data.national_institute =
          this.get_national_institute.national_institute;
        this.national_institute_data.national_institute_program =
          this.get_national_institute.national_institute_program;
      }
    },
    async process_selected_national_program(value: Programs | null) {
      if (value) {
        this.set_degree_gap_loading(true);
        this.set_national_degree_loading(true);
        this.selected_sector = 0;
        this.selected_job = 0;
        const selected_national_institute = {
          national_institute: this.national_institute_data.national_institute,
          national_institute_program: value,
          selected_national_degree_content: "",
          selected_national_degree_file: null
        };
        this.set_national_institute(selected_national_institute);
        if (this.national_institute_data.national_institute) {
          const payload = {
            ref_institute_id:
              this.national_institute_data.national_institute.id,
            ref_program_id: value.id
          };
          await this.fetch_degree_job_comparison(payload);
        }
      } else {
        this.national_institute_data.national_institute_program =
          this.get_national_institute.national_institute_program;
      }
    },
    update_tab(tab_value: ActionsTabs) {
      this.active_tab = tab_value;
      this.configure_skills();
    },
    update_sector() {
      this.selected_job = 0;
      this.configure_skills();
    },
    update_job() {
      this.configure_skills();
    },
    /**
     * FUnction to set upskill courses request payload and navigate to upskill page
     */
    async upskill_courses() {
      // Fetching gap list
      const skills_gap = this.get_selected_sector_job_match_gap(
        this.selected_sector,
        this.selected_job,
        false
      );
      const gap_list = skills_gap.map(
        (skill: ExtractedSkillsWithGroup.Skills) => skill.skill
      );
      if (gap_list.length <= 0) {
        this.root_notification("Skill Gap Not Found");
        return;
      }
      // Fetching job id
      const topjobs = this.national_degree_selected_sector_jobs(
        this.selected_sector
      );
      const job_id = topjobs[this.selected_job].job_id;
      // Setting store variable => upskill_courses_payload
      const payload: UpskillCoursesPayload = {
        gap_list,
        job_id,
        type: UpskillCoursesPayloadTypes.JOB
      };
      // If degree file/content is selected
      if (
        this.get_national_institute.national_institute &&
        (this.get_national_institute.selected_national_degree_file ||
          this.get_national_institute.selected_national_degree_content)
      ) {
        payload.ref_institute_id =
          this.get_national_institute.national_institute.id;
        payload.upload_response_id = this.national_degree_skills.info.file_id;
      }
      // If national institute/program is selected
      else if (
        this.get_national_institute.national_institute &&
        this.get_national_institute.national_institute_program
      ) {
        payload.ref_institute_id =
          this.get_national_institute.national_institute.id;
        payload.ref_program_id =
          this.get_national_institute.national_institute_program.id;
      }
      this.set_upskill_courses_payload(payload);
      this.active_tab = ActionsTabs.DEGREE_SKILLS;
      // Navigate to upskill courses page
      await this.$router.push("result/upskill");
    }
  }
});
