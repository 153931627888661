










































import Vue, { PropType } from "vue";
import { ExtractedSkillsWithGroup } from "@/interfaces/responses/extracted_skills_with_group";
import {
  extract_skill_group_styling,
  get_skill_group_name
} from "@/utils/skills_group";
import DataNotFound from "@/components/shared/DataNotFound.vue";

export default Vue.extend({
  name: "SkillsChipsV2",
  components: { DataNotFound },
  methods: {
    get_skill_group_name,
    extract_skill_group_styling
  },
  props: {
    skills: {
      type: Array as PropType<ExtractedSkillsWithGroup.Skills[]>,
      required: true
    },
    active_skill_group: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    display_border: {
      type: Boolean,
      default: false
    }
  }
});
